
import { defineComponent, reactive, onMounted } from 'vue'

import BannerClassify from '@/components/common/BannerClassify.vue'
import FooterInfo from '@/components/common/FooterInfo.vue'
import CopyrightInfo from '@/components/common/CopyrightInfo.vue'
import MyPagination from '@/components/common/MyPagination.vue'

import RecruitInfo from '@/components/about/RecruitInfo.vue'
import CompanyInfo from '@/components/about/CompanyInfo.vue'
import WorkEnvironment from '@/components/about/WorkEnvironment.vue'
import BannerList from '@/components/about/BannerList.vue'

import { useRoute } from 'vue-router'

import { getJobsApi, getWorkBannerListApi, getBannerByIdApi } from '@/api/api'

interface ClassifyInfo {
  code: string
  label: string
}

export default defineComponent({
  name: 'About',
  components: {
    BannerClassify,
    FooterInfo,
    CopyrightInfo,
    RecruitInfo,
    CompanyInfo,
    WorkEnvironment,
    MyPagination,
    BannerList
  },
  setup() {
    const route = useRoute()

    const state = reactive<{
      bannerUrl: string
      activeCode: string
      classifyList: ClassifyInfo[]
      list: any[]
      arr: any[]
      page: {
        current: number
        size: number
      }
      total: number
      workBannerList: any[]
    }>({
      bannerUrl: '',
      activeCode: '0',
      classifyList: [
        {
          code: '0',
          label: '公司简介'
        },
        {
          code: '1',
          label: '联系我们'
        },
        {
          code: '2',
          label: '人才招聘'
        }
      ],
      list: [],
      arr: [],
      page: {
        current: 1,
        size: 10
      },
      total: 0,
      workBannerList: []
    })

    onMounted(() => {
      if (route.query.type && route.query.type == '1') {
        state.activeCode = '2'
      }
      getWorkBannerListApi()
      getJobs()
      getBannerById()
    })
    const getBannerById = async () => {
      let res = await getBannerByIdApi(8)
      if (res.data.rows && res.data.rows.length) {
        state.bannerUrl = 'https://gwht.scrzhy.com' + res.data.rows[0].savePath
      }
    }
    const changePage = (val: any) => {
      state.page.current = val.currentPage
      state.list = state.arr.slice(
        state.page.current * 10 - 10,
        state.page.current * 10 + 10
      )
    }
    const changeActiveCode = (activeCode: string) => {
      state.activeCode = activeCode
    }

    const getJobs = async () => {
      try {
        const result = await getJobsApi()
        if (result.status !== 200 || !result.data) return false
        const { code, msg, data } = result.data
        if (code !== 0) {
          console.log('报错了', msg)
          return false
        }
        if (data.length) {
          data.forEach((item: any) => {
            item.requireList = item.jobRequire.split('\r\n')
            item.respList = item.jobResp.split('\r\n')
          })
        }

        state.arr = data
        state.list = state.arr.slice(0, 10)
      } catch (e) {}
    }

    const getWorkBannerList = async () => {
      try {
        const result = await getWorkBannerListApi()
        if (result.status !== 200 || !result.data) return false
        const { code, msg, data } = result.data
        if (code !== 0) {
          console.log('报错了', msg)
          return false
        }
        console.log('办公环境成功', data)
        // if (data.length) {
        //   data.forEach((item: any) => {
        //     item.requireList = item.jobRequire.split('\r\n')
        //     item.respList = item.jobResp.split('\r\n')
        //   })
        // }
        // state.workBannerList = data
      } catch (e) {
        console.log('出错', e)
      }
    }

    const switchStatus = (data: any) => {
      state.list.forEach((item) => {
        if (item.id === data.id) {
          item.openStatus = item.openStatus ? false : true
        } else {
          item.openStatus = false
        }
      })
    }

    return {
      changePage,
      state,
      changeActiveCode,
      switchStatus
    }
  }
})
